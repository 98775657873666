import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../../components/Blocks/HeroSection/heroSection';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../../components/Blocks/PlaceImage/placeImage';
import Section from '../../components/Blocks/Section/section';
import FullscreenPhotoSection from '../../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../../components/layout';
import PerformanceSection from '../../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../../components/SEO/seo';
import VideoFullscreen from '../../components/VideoFullscreen/videoFullscreen';

const SnobFigma = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );
  return (
    <Layout >
        <SEO lang="en" title='Building design library for Snob' keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'snob']} description="Our techniques which we use to design in Figma effectively">
        </SEO>

        <HeroSection animationType="noanima" heading="Building design library for Snob" desc="Our techniques which we use to design in Figma effectively."/>

        <FullscreenPhotoSection fileURL="overviewUISnob.png" alt="Component library for Polytech's website"/>

        <Section heading="Components">
            <Paragraph>All components have their page and a lot of states. This helps to keep everything clean and tidy, and much more easily to linking to React/Vue UI-libraries like Storybook.</Paragraph>
        </Section>

        <FullscreenPhotoSection fileURL="fieldsSnobUI.png" alt="Component library for Polytech's website"/>

        <Section heading="Sources">
            <Paragraph>Also, almost every UI element has a source component by which it consists, like in Atomic Design.</Paragraph>
            <Paragraph>That helps keep all states of the UI element consistent. For example, if you need to change the font, you just change the font for a source component and all the states are changing too.</Paragraph>
        </Section>

        <FullscreenPhotoSection fileURL="tabsSnobUI.png" alt="Component library for Polytech's website"/>

        <Section heading="Keeping everything clean">
            <Paragraph>A global design library should consist only of components that can be used in any design, like buttons and inputs. But elements like a carousel for ads or controls for text editor should be on their own page.</Paragraph>
        </Section>

        <FullscreenPhotoSection fileURL="carouselUISnob.png" alt="Component library for Polytech's website"/>

    </Layout>
  ); };

export default SnobFigma;
